<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="12"
      >
        <b-card :title="$t('owwner applicant')">
          <b-row
            v-if="data.owwner"
          >
            <b-col
              v-for="el in ['no','owner', 'applicant','date_time', 'phone', 'identify']"
              :key="el"
              cols="2"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="$t(SelectedOwner(data.owwner)[el])"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-else
            class="text-center"
          >
            البيانات غير متاحة
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="8"
      >

        <b-card>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('chassis_numbe') }}</label>
                <b-form-input
                  v-model="data.chassis_number"
                  class="mt-2"
                  :state="errors.chassis_number ?false:null"
                  :placeholder="$t('Chassis_numbe')"
                />
                <small
                  v-if="errors.chassis_number"
                  class="text-danger"
                >{{ errors.chassis_number[0] }}</small>
                <label>{{ $t('Chassis_numbe') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('chassis_number_type') }}</label>
                <b-form-input
                  v-model="data.chassis_number_type"
                  class="mt-2"
                  :state="errors.chassis_number_type ?false:null"
                  :placeholder="$t('chassis_number_type')"
                />
                <small
                  v-if="errors.chassis_number_type"
                  class="text-danger"
                >{{ errors.chassis_number_type[0] }}</small>
                <label>{{ $t('chassis_number_type') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('cabin_reserve_number') }}</label>
                <b-form-input
                  v-model="data.cabin_reserve_number"
                  class="mt-2"
                  :state="errors.cabin_reserve_number ?false:null"
                  :placeholder="$t('cabin_reserve_number')"
                />
                <small
                  v-if="errors.cabin_reserve_number"
                  class="text-danger"
                >{{ errors.cabin_reserve_number[0] }}</small>
                <label>{{ $t('cabin_reserve_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('cabin_reserve_number_type') }}</label>
                <b-form-input
                  v-model="data.cabin_reserve_number_type"
                  class="mt-2"
                  :state="errors.cabin_reserve_number_type ?false:null"
                  :placeholder="$t('cabin_reserve_number_type')"
                />
                <small
                  v-if="errors.cabin_reserve_number_type"
                  class="text-danger"
                >{{ errors.cabin_reserve_number_type[0] }}</small>
                <label>{{ $t('cabin_reserve_number_type') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Created_year') }}</label>
                <cleave
                  v-model="data.created_year"
                  class="form-control"
                  :class="errors.created_year ?'is-invalid':null"
                  :options="{date: true, datePattern: ['Y']}"
                  :placeholder="$t('Created_year')"
                />
                <small
                  v-if="errors.created_year"
                  class="text-danger"
                >{{ errors.created_year[0] }}</small>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('first_registeraion') }}</label>
                <cleave
                  v-model="data.first_registeraion"
                  class="form-control"
                  :class="errors.first_registeraion ?'is-invalid':null"
                  :options="{date: true, datePattern: ['Y']}"
                  :placeholder="$t('first_registeraion')"
                />
                <small
                  v-if="errors.first_registeraion"
                  class="text-danger"
                >{{ errors.first_registeraion[0] }}</small>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('cabin_number') }}</label>
                <b-form-input
                  v-model="data.cabin_number"
                  class="mt-2"
                  :state="errors.cabin_number ?false:null"
                  :placeholder="$t('cabin_number')"
                />
                <small
                  v-if="errors.cabin_number"
                  class="text-danger"
                >{{ errors.cabin_number[0] }}</small>
                <label>{{ $t('cabin_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('cabin_number_type') }}</label>
                <b-form-input
                  v-model="data.cabin_number_type"
                  class="mt-2"
                  :state="errors.cabin_number_type ?false:null"
                  :placeholder="$t('cabin_number_type')"
                />
                <small
                  v-if="errors.cabin_number_type"
                  class="text-danger"
                >{{ errors.cabin_number_type[0] }}</small>
                <label>{{ $t('cabin_number_type') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_number') }}</label>
                <b-form-input
                  v-model="data.motor_number"
                  class="mt-2"
                  :state="errors.motor_number ?false:null"
                  :placeholder="$t('motor_number')"
                />
                <small
                  v-if="errors.motor_number"
                  class="text-danger"
                >{{ errors.motor_number[0] }}</small>
                <label>{{ $t('motor_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_number_type') }}</label>
                <b-form-input
                  v-model="data.motor_number_type"
                  class="mt-2"
                  :state="errors.motor_number_type ?false:null"
                  :placeholder="$t('motor_number_type')"
                />
                <small
                  v-if="errors.motor_number_type"
                  class="text-danger"
                >{{ errors.motor_number_type[0] }}</small>
                <label>{{ $t('motor_number_type') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_reserve_number') }}</label>
                <b-form-input
                  v-model="data.motor_reserve_number"
                  class="mt-2"
                  :state="errors.motor_reserve_number ?false:null"
                  :placeholder="$t('motor_reserve_number')"
                />
                <small
                  v-if="errors.motor_reserve_number"
                  class="text-danger"
                >{{ errors.motor_reserve_number[0] }}</small>
                <label>{{ $t('motor_reserve_number') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_reserve_number_type') }}</label>
                <b-form-input
                  v-model="data.motor_reserve_number_type"
                  class="mt-2"
                  :state="errors.motor_reserve_number_type ?false:null"
                  :placeholder="$t('motor_reserve_number_type')"
                />
                <small
                  v-if="errors.motor_reserve_number_type"
                  class="text-danger"
                >{{ errors.motor_reserve_number_type[0] }}</small>
                <label>{{ $t('motor_reserve_number_type') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_manufacturer') }}</label>
                <b-form-input
                  v-model="data.motor_manufacturer"
                  class="mt-2"
                  :state="errors.motor_manufacturer ?false:null"
                  :placeholder="$t('motor_manufacturer')"
                />
                <small
                  v-if="errors.motor_manufacturer"
                  class="text-danger"
                >{{ errors.motor_manufacturer[0] }}</small>
                <label>{{ $t('motor_manufacturer') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('motor_model') }}</label>
                <b-form-input
                  v-model="data.motor_model"
                  class="mt-2"
                  :state="errors.motor_model ?false:null"
                  :placeholder="$t('motor_model')"
                />
                <small
                  v-if="errors.motor_model"
                  class="text-danger"
                >{{ errors.motor_model[0] }}</small>
                <label>{{ $t('motor_model') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <label>{{ $t('color') }}</label>
                <b-form-input
                  v-model="data.color"
                  class="mt-2"
                  :state="errors.color ?false:null"
                  :placeholder="$t('color')"
                />
                <small
                  v-if="errors.color"
                  class="text-danger"
                >{{ errors.color[0] }}</small>
                <label>{{ $t('color') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('car_type') }}</label>
                <b-form-select
                  v-model="data.car_type"
                  :options="typeoptions"
                />
                <small
                  v-if="errors.car_type"
                  class="text-danger"
                >{{ errors.car_type[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('fuel_type') }}</label>
                <b-form-select
                  v-model="data.fuel_type"
                  :options="fueltypeoptions"
                />
                <small
                  v-if="errors.fuel_type"
                  class="text-danger"
                >{{ errors.fuel_type[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Expiration_date') }}</label>
                <b-form-datepicker
                  v-model="data.expiration_date"
                  :placeholder="$t('Expiration_date')"
                  :state="errors.expiration_date ?false:null"
                />
                <small
                  v-if="errors.expiration_date"
                  class="text-danger"
                >{{ errors.expiration_date[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <div class="form-label-group">
                <label>{{ $t('notes') }}</label>
                <b-form-input
                  v-model="data.notes"
                  class="mt-2"
                  :state="errors.notes ?false:null"
                  :placeholder="$t('notes')"
                />
                <small
                  v-if="errors.notes"
                  class="text-danger"
                >{{ errors.notes[0] }}</small>
                <label>{{ $t('notes') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('public/specific') }}</label>
                <b-form-checkbox
                  v-model="data.owner_type"
                  :value="true"
                  :state="errors.owner_type ?false:null"
                >
                  {{ data.owner_type ? $t('specific') : $t('public') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.owner_type"
                class="text-danger"
              >{{ errors.owner_type[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('brake') }}</label>
                <b-form-checkbox
                  v-model="data.brake"
                  :value="true"
                  :state="errors.brake ?false:null"
                >
                  {{ data.brake ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.brake"
                class="text-danger"
              >{{ errors.brake[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('light') }}</label>
                <b-form-checkbox
                  v-model="data.light"
                  :value="true"
                  :state="errors.light ?false:null"
                >
                  {{ data.light ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.light"
                class="text-danger"
              >{{ errors.light[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('combustion') }}</label>
                <b-form-checkbox
                  v-model="data.combustion"
                  :value="true"
                  :state="errors.combustion ?false:null"
                >
                  {{ data.combustion ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.combustion"
                class="text-danger"
              >{{ errors.combustion[0] }}</small>
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <div>
                <label>{{ $t('wheel') }}</label>
                <b-form-checkbox
                  v-model="data.wheel"
                  :value="true"
                  :state="errors.wheel ?false:null"
                >
                  {{ data.wheel ? $t('ok') : $t('bad') }}
                </b-form-checkbox>
              </div>
              <small
                v-if="errors.wheel"
                class="text-danger"
              >{{ errors.wheel[0] }}</small>
            </b-col>
          </b-row>
        </b-card>

      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-card :title="$t('applicantions')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.owwner"
                :options="Owwners"
                :reduce="Owwners => Owwners.id"
                label="owner"
              >
                <template #option="{ no,applicant,owner }">
                  <span> {{ no }} - {{ applicant }}-({{ owner }})</span>
                </template>
              </v-select>
              <small
                v-if="errors.owwner"
                class="text-danger"
              >{{ errors.owwner[0] }}</small>
            </b-card>
          </b-col>
          <b-col
            cols="12"
          >
            <b-card :title="$t('Carrs')">
              <label>{{ $t('please select...') }}</label>
              <template #option="{ manufacturer,model,car_type }">
                <span> {{ manufacturer }} ({{ model }} -- {{ $t(car_type) }})</span>
              </template>
              <v-select
                v-model="data.carr"
                :options="Carrs"
                :reduce="Carrs => Carrs.id"
                label="name"
              >
                <template #option="{ manufacturer,model,car_type }">
                  <span> {{ manufacturer }} ({{ model }} -- {{ $t(car_type) }})</span>
                </template>
              </v-select>
              <small
                v-if="errors.carr"
                class="text-danger"
              >{{ errors.carr[0] }}</small>
            </b-card>
          </b-col>

          <b-col
            cols="12"
          >
            <b-card :title="$t('Platees')">
              <label>{{ $t('please select...') }}</label>
              <v-select
                v-model="data.platee"
                :options="Platees"
                :reduce="Platees => Platees.id"
                label="number"
              />

              <small
                v-if="errors.platee"
                class="text-danger"
              >{{ errors.platee[0] }}</small>
            </b-card>
          </b-col>
          <b-col
            cols="12"
          >
            <b-card :title="$t('invoice')">
              {{ $t('Total') }} : {{ Total }}<br>
            </b-card>
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="create"
                  >
                    {{ $t('Create') }}
                  </b-button>
                  <b-button
                    class="w-100"
                    variant="gradient-danger"
                    @click="resetData"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

        </b-row>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-col
          cols="12"
        >
          <b-card :title="$t('Carrs')">
            <b-row
              v-if="data.carr"
            >
              <b-col
                v-for="el in ['no','manufacturer', 'model','passenger', 'axle', 'cylinder','front_overhang','rear_overhang','axle_hole','length','width','wight', 'motor_power', 'motor_size', 'car_type']"
                :key="el"
                cols="2"
              >
                <b-form-group>
                  {{ $t(el) }}
                  <b-form-input
                    plaintext
                    :value="$t(SelectedCar(data.carr)[el])"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <div
              v-else
              class="text-center"
            >
              البيانات غير متاحة
            </div>
          </b-card>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        { value: 'Container', text: 'كونتينر' },
        { value: 'ROR', text: 'رورو' },
        { value: 'Land_shipping', text: 'شحن بري' },
        { value: 'Other', text: 'غير ذلك' },
      ],
      typeoptions: [
        { value: 'Normal', text: 'عادي' },
        { value: 'Assa', text: 'قصة' },
      ],
      fueltypeoptions: [
        { value: 'mazot', text: 'مازوت' },
        { value: 'benzin', text: 'بنزين' },
        { value: 'gaz', text: 'غاز' },
      ],
      data: {
        created_year: '',
        car: null,
        platee: null,
        check_in_date: `${this.moment().format('YYYY-MM-DD')}`,
        owner_type: true,
        brake: true,
        light: true,
        combustion: true,
        fee: 0,
        total: 0,
        wheel: true,
      },
      datanew: false,
      created_year: {
        N: '1992',
        P: '1993',
        R: '1994',
        S: '1995',
        T: '1996',
        V: '1997',
        W: '1998',
        X: '1999',
        Y: '2000',
        1: '2001',
        2: '2002',
        3: '2003',
        4: '2004',
        5: '2005',
        6: '2006',
        7: '2007',
        8: '2008',
        9: '2009',
        A: '2010',
        B: '2011',
        C: '2012',
        D: '2013',
        E: '2014',
        F: '2015',
        G: '2016',
        H: '2017',
        J: '2018',
        K: '2019',
        L: '2020',
        M: '2021',
      },
      errors: [],
      fileErrors: '',

    }
  },
  computed: {
    Total() {
      let totalfee = 0
      if (this.data.fee) totalfee = parseInt(this.data.fee, 10)
      return totalfee
    },
    Platees() {
      return this.$store.getters['platee/GetElements']
    },
    Checkk() {
      return this.$store.getters['checkk/GetAvailableElements']
    },
    Owwners() { return this.$store.getters['owwner/GetElements'] },
    Tolls() { return this.$store.getters['toll/GetElements'] },

    Carrs() { return this.$store.getters['carr/GetElements'] },
  },
  watch: {
    // eslint-disable-next-line func-names
    'data.chassis_number': function (newEl) {
      const year = this.created_year[newEl.slice(10, 11)]
      if (year) {
        this.data.created_year = year
      }
    },
    'data.owwner': function (val) {
      this.resetData()
      if (val) {
        const SelectedOwwners = this.Owwners.find(el => el.id === val)
        const SelectedOwners = SelectedOwwners.checkks[SelectedOwwners.owwners_count - 1]
        if (SelectedOwwners.owwners_count > 0) {
          this.datanew = false
          this.data.brake = SelectedOwners.brake
          this.data.platee = SelectedOwners.platee_id
          this.data.cabin_number = SelectedOwners.cabin_number
          this.data.cabin_number_type = SelectedOwners.cabin_number_type
          this.data.cabin_reserve_number = SelectedOwners.cabin_reserve_number
          this.data.cabin_reserve_number_type = SelectedOwners.cabin_reserve_number_type
          this.data.car_type = SelectedOwners.car_type
          this.data.carr = SelectedOwners.carr_id
          const car = this.SelectedCar(SelectedOwners.carr_id)
          if (car.car_type === 'tourism' && car.motor_power <= 1600) this.data.fee = this.Tolls.tourismOneFirst
          else if (car.car_type === 'tourism' && car.motor_power <= 2000) this.data.fee = this.Tolls.tourismTwoFirst
          else if (car.car_type === 'tourism' && car.motor_power > 2000) this.data.fee = this.Tolls.tourismThreeFirst
          else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'agricultural' || car.car_type === 'work') && car.wight <= 3000) this.data.fee = this.Tolls.otherOneFirst
          else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'agricultural' || car.car_type === 'work') && car.wight <= 11000) this.data.fee = this.Tolls.otherTwoFirst
          else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'agricultural' || car.car_type === 'work') && car.wight > 11000) this.data.fee = this.Tolls.otherThreeFirst
          else if (car.car_type === 'microbus' && car.passenger <= 14) this.data.fee = this.Tolls.microbusOneFirst
          else if (car.car_type === 'microbus' && car.passenger <= 30) this.data.fee = this.Tolls.microbusTwoFirst
          else if (car.car_type === 'microbus' && car.passenger > 30) this.data.fee = this.Tolls.microbusThreeFirst
          else if (car.car_type === 'transport_ride') this.data.fee = this.Tolls.workOneFirst
          else if (car.car_type === 'motor' && car.axle === 2) this.data.fee = this.Tolls.motorOneFirst
          else if (car.car_type === 'motor' && car.axle === 3) this.data.fee = this.Tolls.motorTwoFirst
          else if (car.car_type === 'motor' && car.axle === 4) this.data.fee = this.Tolls.motorThreeFirst
          this.data.chassis_number = SelectedOwners.chassis_number
          this.data.created_year = SelectedOwners.created_year
          this.data.chassis_number_type = SelectedOwners.chassis_number_type
          this.data.color = SelectedOwners.color
          this.data.combustion = SelectedOwners.combustion
          this.data.expiration_date = SelectedOwners.expiration_date
          this.data.first_check = SelectedOwners.first_check
          this.data.first_registeraion = SelectedOwners.first_registeraion
          this.data.fuel_type = SelectedOwners.fuel_type
          this.data.light = SelectedOwners.light
          this.data.motor_manufacturer = SelectedOwners.motor_manufacturer
          this.data.motor_model = SelectedOwners.motor_model
          this.data.motor_number = SelectedOwners.motor_number
          this.data.motor_number_type = SelectedOwners.motor_number_type
          this.data.motor_reserve_number = SelectedOwners.motor_reserve_number
          this.data.motor_reserve_number_type = SelectedOwners.motor_reserve_number_type
          this.data.owner_type = SelectedOwners.owner_type
          this.data.owwner_id = SelectedOwners.owwner_id
          this.data.first_check = false
          this.data.wheel = SelectedOwners.wheel
          this.datanew = false
        } else {
          this.data.brake = true
          this.data.cabin_number = null
          this.data.cabin_number_type = null
          this.data.cabin_reserve_number = null
          this.data.cabin_reserve_number_type = null
          this.data.car_type = null
          this.data.chassis_number = null
          this.data.created_year = null
          this.data.chassis_number_type = null
          this.data.color = null
          this.data.combustion = true
          this.data.wheel = true
          this.data.first_registeraion = null
          this.data.fuel_type = null
          this.data.light = true
          this.data.motor_manufacturer = null
          this.data.motor_model = null
          this.data.motor_number = null
          this.data.motor_number_type = null
          this.data.motor_reserve_number = null
          this.data.motor_reserve_number_type = null
          this.data.owner_type = true
          this.data.owwner_id = null
          this.datanew = true
        }
      } else {
        this.clearData()
      }
    },
    // eslint-disable-next-line func-names
    'data.carr': function (cartype) {
      this.data.platee = null
      const car = this.SelectedCar(cartype)
      this.data.fee = 0
      this.getPlatee(car.car_type)
      if (this.data.fee < 1) {
        if (car.car_type === 'tourism' && car.motor_power <= 1600) this.data.fee = this.Tolls.tourismOneFirst
        else if (car.car_type === 'tourism' && car.motor_power <= 2000) this.data.fee = this.Tolls.tourismTwoFirst
        else if (car.car_type === 'tourism' && car.motor_power > 2000) this.data.fee = this.Tolls.tourismThreeFirst
        else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'agricultural' || car.car_type === 'work') && car.wight <= 3000) this.data.fee = this.Tolls.otherOneFirst
        else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'agricultural' || car.car_type === 'work') && car.wight <= 11000) this.data.fee = this.Tolls.otherTwoFirst
        else if ((car.car_type === 'small' || car.car_type === 'big' || car.car_type === 'agricultural' || car.car_type === 'work') && car.wight > 11000) this.data.fee = this.Tolls.otherThreeFirst
        else if (car.car_type === 'microbus' && car.passenger <= 14) this.data.fee = this.Tolls.microbusOneFirst
        else if (car.car_type === 'microbus' && car.passenger <= 30) this.data.fee = this.Tolls.microbusTwoFirst
        else if (car.car_type === 'microbus' && car.passenger > 30) this.data.fee = this.Tolls.microbusThreeFirst
        else if (car.car_type === 'transport_ride') this.data.fee = this.Tolls.workOneFirst
        else if (car.car_type === 'motor' && car.axle === 2) this.data.fee = this.Tolls.motorOneFirst
        else if (car.car_type === 'motor' && car.axle === 3) this.data.fee = this.Tolls.motorTwoFirst
        else if (car.car_type === 'motor' && car.axle === 4) this.data.fee = this.Tolls.motorThreeFirst
      }
    },
  },
  created() {
    this.data.expiration_date = this.moment().add(12, 'months').format('YYYY-MM-DD')
    this.$store.dispatch('owwner/GetAvailableElements')
    this.$store.dispatch('carr/GetElements')
    this.$store.dispatch('toll/GetPluckedElements')
  },
  methods: {
    clearData() {
      this.data = {
        fee: 0,
        total: 0,
        datanew: true,
        owner_type: true,
        brake: true,
        platee: null,
        light: true,
        combustion: true,
        wheel: true,
        expiration_date: this.moment().add(3, 'months').format('YYYY-MM-DD'),
      }
    },
    getPlatee(val) {
      this.$store.dispatch('platee/GetAvailableElements', val).then(() => {
      })
    },
    getData(val) {
      this.$store.dispatch('checkk/GetAvailableElements', val).then(() => {
        this.data = this.Element
      })
    },
    SelectedCar(val) {
      return this.Carrs.find(el => el.id === val)
    },
    SelectedOwner(val) {
      return this.Owwners.find(el => el.id === val)
    },
    resetData() {
      this.data = {
        fee: 0,
        total: 0,
        datanew: true,
        owner_type: true,
        brake: true,

        light: true,
        combustion: true,
        wheel: true,
        platee: null,
        carr: null,
        expiration_date: this.moment().add(3, 'months').format('YYYY-MM-DD'),
      }
      this.errors = []
    },
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    ExpirationDateAfter(months) {
      // const current = new Date()
      // current.setMonth(current.getMonth() + months)
      // const day = current.getDate()
      // const month = current.getMonth()
      // const year = current.getFullYear()
      // this.data.expiration_date = `${year}-${month + 1}-${day}`
      this.data.expiration_date = this.moment().add(months, 'months').format('YYYY-MM-DD')
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.errors = []
      if ((this.data.platee === null || this.data.platee === '') && this.datanew) {
        alert('الرجاء اختيار اللوحة')
      } else {
        this.$store.dispatch('checkk/Create', this.data).then(() => {
          this.Platees.splice(this.Platees[0], 1)
          this.clearData()
        }).catch(error => {
          this.errors = error.response.data.errors
        })
      }
    },
  },
}
</script>
